import React, { useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import Button from "@mui/material/Button";
import { LoadingButton } from "@mui/lab";
import CardContent from "@mui/material/CardContent";
import Avatar from "@mui/material/Avatar";
import Grid from "@mui/material/Grid";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useNavigate } from "react-router-dom";
import Container from "components/Container";
import { getCheckoutUrl, getPortalUrl } from "util/stripePayment";
import { getPremiumStatus } from "util/checkPremiumStatus";
import { auth } from "../../../../firebase";
import { onAuthStateChanged } from "firebase/auth";

const mock = [
  {
    title: "Basic",
    subtitle: "Is perfect for startups",
    price: { monthly: "$299", annual: "$249" },
    features: [
      "3 Users (1 Admin + 2 User)",
      "5 GB Storage",
      "Inventory Management",
      "Streamlined Purchasing",
      "Project Analytics",
      "Track Purchases",
      "Key Vendor APIs",
      "Company Part Number (CPN)",
    ],
    isHighlighted: false,
    priceId: {
      monthly: "price_1OFMfELHlVY9oaytrotioZvO",
      yearly: "price_1OFMgFLHlVY9oayt03BtqnxD",
    },
    loading: "basic",
  },
  // {
  //   title: "Premium",
  //   subtitle: "For Corporate companies",
  //   price: { monthly: "$649", annual: "$7,440" },
  //   features: [
  //     "All in Starter plan",
  //     "Google Ads",
  //     "SSO via Google",
  //     "API access",
  //   ],
  //   isHighlighted: true,
  //   priceId: {
  //     monthly: "price_1OEq0dLHlVY9oaytONauvIq1",
  //     yearly: "price_1OEq17LHlVY9oaythzsoWMqg",
  //   },
  //   loading: "premium",
  // },
  // {
  //   title: "Enterprise",
  //   subtitle: "For big companies",
  //   price: { monthly: "Contact Sales", annual: "Contact Sales" },
  //   features: ["All features", "Custom Support"],
  //   isHighlighted: false,
  //   loading: "enterprise",
  // },
];

const Main = () => {
  const [loading, setLoading] = useState({
    basic: false,
    premium: false,
  });

  const [initialLoading, setInitialLoading] = useState(true);
  const navigate = useNavigate();
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });

  const [pricingOption, setPricingOption] = useState("annual");

  const handleClick = (event, newPricingOption) => {
    setPricingOption(newPricingOption);
  };
  async function checkPremiuim() {
    getPremiumStatus().then((subscription) => {
      setInitialLoading(false);
      if (subscription.status) {
        setSubscriptionActive(true);
      }
    });
  }
  onAuthStateChanged(auth, (user) => {
    if (user) {
      checkPremiuim();
    }
  });
  const [subscriptionActive, setSubscriptionActive] = useState(false);

  const renderToggler = () => (
    <Box display={"flex"} justifyContent={"center"} marginBottom={2}>
      <ToggleButtonGroup value={pricingOption} exclusive onChange={handleClick}>
        <ToggleButton
          value="annual"
          size={isMd ? "large" : "small"}
          sx={{
            backgroundColor:
              pricingOption === "annual"
                ? `${theme.palette.primary.light} !important`
                : "transparent",
            border: `1px solid ${theme.palette.primary.main}`,
          }}
        >
          <Typography
            variant="subtitle2"
            sx={{
              fontWeight: 700,
              color:
                pricingOption === "annual" ? "common.white" : "text.primary",
            }}
          >
            Annual
          </Typography>
        </ToggleButton>
        <ToggleButton
          value="monthly"
          size={isMd ? "large" : "small"}
          sx={{
            backgroundColor:
              pricingOption === "monthly"
                ? `${theme.palette.primary.light} !important`
                : "transparent",
            border: `1px solid ${theme.palette.primary.main}`,
          }}
        >
          <Typography
            variant="subtitle2"
            sx={{
              fontWeight: 700,
              color:
                pricingOption !== "annual" ? "common.white" : "text.primary",
            }}
          >
            Monthly
          </Typography>
        </ToggleButton>
      </ToggleButtonGroup>
    </Box>
  );

  return (
    <Box>
      <Box
        sx={{
          position: "relative",
          backgroundColor: theme.palette.alternate.main,
          backgroundImage: `linear-gradient(120deg, ${theme.palette.background.paper} 0%, ${theme.palette.alternate.main} 100%)`,
          marginTop: -13,
          paddingTop: 13,
        }}
      >
        <Container position={"relative"} zIndex={3}>
          <Box>
            <Box marginBottom={2}>
              <Typography
                variant="h3"
                gutterBottom
                align={"center"}
                sx={{
                  fontWeight: 900,
                }}
              >
                Pricing plans
              </Typography>
              <Typography
                variant="h6"
                component="p"
                color="text.primary"
                align={"center"}
              >
                Improve your hardware design efficiency with Partix MVP: We handle the extras, you focus on engineering excellence.
              </Typography>
            </Box>
            {renderToggler()}
          </Box>
        </Container>
      </Box>
      <Container sx={{ paddingY: 0 }}>
        <Grid container spacing={4} justifyContent={"center"}>
          {mock.map((item, i) => (
            <Grid item xs={12} md={4} key={i}>
              <Box
                component={Card}
                height={1}
                display={"flex"}
                flexDirection={"column"}
                variant={"outlined"}
              >
                <CardContent
                  sx={{
                    padding: 4,
                  }}
                >
                  <Box marginBottom={2}>
                    <Typography variant={"h4"} fontWeight={600} gutterBottom>
                      {item.title}
                    </Typography>
                    <Typography color={"text.secondary"}>
                      {item.subtitle}
                    </Typography>
                  </Box>
                  <Box
                    display={"flex"}
                    alignItems={"baseline"}
                    marginBottom={2}
                  >
                    <Typography variant={"h3"} fontWeight={700}>
                      {pricingOption === "annual"
                        ? item.price.annual
                        : item.price.monthly}
                    </Typography>
                    {item.title !== "Enterprise" && (
                      <Typography
                        variant={"subtitle1"}
                        color={"text.secondary"}
                        fontWeight={700}
                      >
                        {pricingOption === "annual" ? "/month*" : "/month*"}
                        {/* /month * */}
                      </Typography>
                    )}
                  </Box>
                  <Grid container spacing={1}>
                    {item.features.map((feature, j) => (
                      <Grid item xs={12} key={j}>
                        <Box
                          component={ListItem}
                          disableGutters
                          width={"auto"}
                          padding={0}
                        >
                          <Box
                            component={ListItemAvatar}
                            minWidth={"auto !important"}
                            marginRight={2}
                          >
                            <Box
                              component={Avatar}
                              bgcolor={theme.palette.primary.main}
                              width={20}
                              height={20}
                            >
                              <svg
                                width={12}
                                height={12}
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                  clipRule="evenodd"
                                />
                              </svg>
                            </Box>
                          </Box>
                          <ListItemText primary={feature} />
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </CardContent>
                <Box flexGrow={1} />
                <CardActions sx={{ justifyContent: "flex-end", padding: 4 }}>
                  {item.title === "Enterprise" ? (
                    <Button size={"large"} variant={"contained"}>
                      Contact Sales
                    </Button>
                  ) : (
                    <LoadingButton
                      onClick={() => {
                        if (subscriptionActive) {
                          setLoading({
                            ...loading,
                            [item.loading]: true,
                          });
                          getPortalUrl()
                            .then((res) => {
                              setLoading({
                                ...loading,
                                [item.loading]: false,
                              });
                              window.location.href = res;
                            })
                            .catch((err) => {
                              setLoading({
                                ...loading,
                                [item?.loading]: false,
                              });
                              if (err.code === "noauth") {
                                navigate("/usersignin");
                              }
                            });
                        }
                        setLoading({
                          ...loading,
                          [item.loading]: true,
                        });
                        getCheckoutUrl(
                          pricingOption === "annual"
                            ? item.priceId.yearly
                            : item.priceId.monthly
                        )
                          .then((res) => {
                            setLoading({
                              ...loading,
                              [item.loading]: false,
                            });
                            window.location.href = res;
                          })
                          .catch((err) => {
                            setLoading({
                              ...loading,
                              [item?.loading]: false,
                            });
                            if (err.code === "noauth") {
                              navigate("/usersignin");
                            }
                          });
                      }}
                      size={"large"}
                      variant={"contained"}
                      loading={loading[item.loading]}
                      disabled={initialLoading}
                    >
                      Buy Now
                    </LoadingButton>
                  )}
                </CardActions>
              </Box>
            </Grid>
          ))}
        </Grid>
        <Typography
          sx={{
            marginTop: 4,
            fontStyle: "italic",
            textAlign: "center",
          }}
        >
          <sup
            style={{
              //fontWeight: "bold",
              marginRight: "6px",
              fontStyle: "normal",
              //fontWeight: "bold",
            }}
          >
            *
          </sup>
          Your purchases may be subject to local taxes and Stripe payment service fees based
          on the billing information you provide. The final charge may differ from the amount
          shown here and will be detailed on your invoice.
        </Typography>
      </Container>
    </Box>
  );
};

export default Main;
